import React from "react"

import { BuyLink } from "./buy-link";
import cover from "../images/the-new-toys-cover.png";

const BuyLinks = ({className, "class": cls = className}) => (
    <div className={`content-box buy-links ${cls}`}>
        <BuyLink target="" className="blog-post-buy-image no-hover" tabIndex="-1" to="/where-to-buy" noDeco={true} button={false}>
            <img className="home__book-cover" src={cover} alt="JavaScript: The New Toys - Cover" role="presentation" />
        </BuyLink>
        <div>
            <BuyLink target="" className="blog-post-buy-link" to="/where-to-buy" >Buy Now</BuyLink>
        </div>
    </div>
);

export function SidebarWrapper({"class": cls = "", className = cls, contentBox = true, main, subs, side}) {
    if (subs && !Array.isArray(subs)) {
        subs = [subs];
    }
    return (
        <div className={`${className} flex-container align-flex-start blog-text`}>
            <div className="blog-post-main-column">
                <div className={`blog-post ${contentBox ? "content-box" : ""}`}>
                    {main}
                </div>
                <BuyLinks className="blog-box buy-links-wide" />
                {subs?.map((sub, index) => (
                    <div key={index} className="blog-box content-box">
                        {sub}
                    </div>
                ))}
            </div>
            <div className="blog-post-side-column">
                {side &&
                    <div className="content-box">
                        {side}
                    </div>
                }
                <BuyLinks />
            </div>
        </div>
    );
}