// Copyright (C) 2020 Farsight Software Ltd.. All rights reserved.
import React from "react";
import Layout from "../components/layout";
import PageInfo from "../components/pageinfo";
import { SidebarWrapper } from "../components/sidebar-wrapper";

const TOCLevel1 = ({ className, open, text, children }) => (
    <details open={open} className={`toc-1 ${className}`}>
        <summary dangerouslySetInnerHTML={{ __html: text }} />
        {children /*?? <br/>*/}
    </details>
);
const TOCLevel2 = ({text, children}) => (
    <>
        <h3 className="toc-2" dangerouslySetInnerHTML={{ __html: text}} />
        {children && <ul>{children}</ul>}
    </>
);
const TOCLevel3 = ({text, children}) => (
    <li className="toc-3">
        <span dangerouslySetInnerHTML={{ __html: text}} />
        {children && <ul>{children}</ul>}
    </li>
);
const TOCLevel4 = ({text}) => (
    <li className="toc-4" dangerouslySetInnerHTML={{ __html: text}} />
);

const TOC = () => (
    <div>
        <div style={{display: "flex", justifyContent: "center" }}>
            <h1>Table of Contents</h1>
        </div>
        <TOCLevel1 open={true} text="Chapter 1: The New Toys in ES2015–ES2020, and Beyond">
            <TOCLevel2 text="Definitions, Who's Who, and Terminology">
                <TOCLevel3 text="Ecma? ECMAScript? TC39?"></TOCLevel3>
                <TOCLevel3 text="ES6? ES7? ES2015? ES2020?"></TOCLevel3>
                <TOCLevel3 text={"JavaScript \"Engines,\" Browsers, and Others"}></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text={"What Are the \"New Toys\"?"}></TOCLevel2>
            <TOCLevel2 text="How Do New Toys Get Created?">
                <TOCLevel3 text="Who's in Charge"></TOCLevel3>
                <TOCLevel3 text="The Process"></TOCLevel3>
                <TOCLevel3 text="Getting Involved"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Keeping Up with the New Toys"></TOCLevel2>
            <TOCLevel2 text="Using Today's Toys in Yesterday's Environments, and Tomorrow's Toys Today">
                <TOCLevel3 text="Transpiling an Example with Babel"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Review"></TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 2: Block-Scoped Declarations: <code>let</code> and <code>const</code>">
            <TOCLevel2 text="An Introduction to <code>let</code> and <code>const</code>"></TOCLevel2>
            <TOCLevel2 text="True Block Scope"></TOCLevel2>
            <TOCLevel2 text="Repeated Declarations Are an Error"></TOCLevel2>
            <TOCLevel2 text="Hoisting and the Temporal Dead Zone"></TOCLevel2>
            <TOCLevel2 text="A New Kind of Global"></TOCLevel2>
            <TOCLevel2 text="<code>const</code>: Constants for JavaScript">
                <TOCLevel3 text="<code>const</code> Basics"></TOCLevel3>
                <TOCLevel3 text="Objects Referenced by a <code>const</code> Are Still Mutable"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Block Scope in Loops">
                <TOCLevel3 text={"The \"Closures in Loops\" Problem"}></TOCLevel3>
                <TOCLevel3 text="Bindings: How Variables, Constants, and Other Identifiers Work"></TOCLevel3>
                <TOCLevel3 text="<code>while</code> and <code>do-while</code> Loops"></TOCLevel3>
                <TOCLevel3 text="Performance Implications"></TOCLevel3>
                <TOCLevel3 text="<code>const</code> in Loop Blocks"></TOCLevel3>
                <TOCLevel3 text="<code>const</code> in <code>for-in</code> Loops"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Old Habits to New">
                <TOCLevel3 text="Use <code>const</code> or <code>let</code> Instead of <code>var</code>"></TOCLevel3>
                <TOCLevel3 text="Keep Variables Narrowly Scoped"></TOCLevel3>
                <TOCLevel3 text="Use Block Scope Instead of Inline Anonymous Functions"></TOCLevel3>
            </TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 3: New Function Features">
            <TOCLevel2 text="Arrow Functions and Lexical <code>this</code>, <code>super</code>, etc.">
                <TOCLevel3 text="Arrow Function Syntax"></TOCLevel3>
                <TOCLevel3 text="Arrow Functions and Lexical <code>this</code>"></TOCLevel3>
                <TOCLevel3 text="Arrow Functions Cannot Be Constructors"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Default Parameter Values">
                <TOCLevel3 text="Defaults Are Expressions"></TOCLevel3>
                <TOCLevel3 text="Defaults Are Evaluated in Their Own Scope"></TOCLevel3>
                <TOCLevel3 text="Defaults Don't Add to the Arity of the Function"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text={"\"Rest\" Parameters"}></TOCLevel2>
            <TOCLevel2 text="Trailing Commas in Parameter Lists and Function Calls"></TOCLevel2>
            <TOCLevel2 text="The Function name Property"></TOCLevel2>
            <TOCLevel2 text="Function Declarations in Blocks">
                <TOCLevel3 text="Function Declarations in Blocks: Standard Semantics"></TOCLevel3>
                <TOCLevel3 text="Function Declarations in Blocks: Legacy Web Semantics"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Old Habits to New">
                <TOCLevel3 text="Use Arrow Functions Instead of Various this Value Workarounds"></TOCLevel3>
                <TOCLevel3 text="Use Arrow Functions for Callbacks When Not Using <code>this</code> or <code>arguments</code>"></TOCLevel3>
                <TOCLevel3 text="Consider Arrow Functions Elsewhere As Well"></TOCLevel3>
                <TOCLevel3 text="Don't Use Arrow Functions When the Caller Needs to Control the Value of <code>this</code>"></TOCLevel3>
                <TOCLevel3 text="Use Default Parameter Values Rather Than Code Providing Defaults"></TOCLevel3>
                <TOCLevel3 text="Use a Rest Parameter Instead of the <code>arguments</code> Keyword"></TOCLevel3>
                <TOCLevel3 text="Consider Trailing Commas If Warranted"></TOCLevel3>
            </TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 4: Classes">
            <TOCLevel2 text="What Is a Class?"></TOCLevel2>
            <TOCLevel2 text="Introducing the New <code>class</code> Syntax"></TOCLevel2>
            <TOCLevel2 text="Adding a Constructor">
                <TOCLevel3 text="Adding Instance Properties"></TOCLevel3>
                <TOCLevel3 text="Adding a Prototype Method"></TOCLevel3>
                <TOCLevel3 text="Adding a Static Method"></TOCLevel3>
                <TOCLevel3 text="Adding an Accessor Property"></TOCLevel3>
                <TOCLevel3 text="Computed Method Names"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Comparing with the Older Syntax"></TOCLevel2>
            <TOCLevel2 text="Creating Subclasses">
                <TOCLevel3 text="The <code>super</code> Keyword"></TOCLevel3>
                <TOCLevel3 text="Writing Subclass Constructors"></TOCLevel3>
                <TOCLevel3 text="Inheriting and Accessing Superclass Prototype Properties and Methods"></TOCLevel3>
                <TOCLevel3 text="Inheriting Static Methods">
                    <TOCLevel4 text="super in Static Methods"></TOCLevel4>
                </TOCLevel3>
                <TOCLevel3 text="Methods Returning New Instances"></TOCLevel3>
                <TOCLevel3 text="Subclassing Built-ins"></TOCLevel3>
                <TOCLevel3 text="Where <code>super</code> Is Available"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Leaving Off <code>Object.prototype</code>"></TOCLevel2>
            <TOCLevel2 text="<code>new.target</code>"></TOCLevel2>
            <TOCLevel2 text="<code>class</code> Declarations vs. class Expressions">
                <TOCLevel3 text="<code>class</code> Declarations"></TOCLevel3>
                <TOCLevel3 text="<code>class</code> Expressions"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="More to Come"></TOCLevel2>
            <TOCLevel2 text="Old Habits to New">
                <TOCLevel3 text="Use <code>class</code> When Creating Constructor Functions"></TOCLevel3>
            </TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 5: New Object Features">
            <TOCLevel2 text="Computed Property Names"></TOCLevel2>
            <TOCLevel2 text="Shorthand Properties"></TOCLevel2>
            <TOCLevel2 text="Getting and Setting an Object's Prototype">
                <TOCLevel3 text="<code>Object.setPrototypeOf</code>"></TOCLevel3>
                <TOCLevel3 text="The <code>__proto__</code> Property on Browsers"></TOCLevel3>
                <TOCLevel3 text="The <code>__proto__</code> Literal Property Name on Browsers"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Method Syntax, and <code>super</code> Outside Classes"></TOCLevel2>
            <TOCLevel2 text="Symbol">
                <TOCLevel3 text="Why Symbols?"></TOCLevel3>
                <TOCLevel3 text="Creating and Using Symbols"></TOCLevel3>
                <TOCLevel3 text="Symbols Are Not for Privacy"></TOCLevel3>
                <TOCLevel3 text="Global Symbols"></TOCLevel3>
                <TOCLevel3 text="Well-Known Symbols"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="New Object Functions">
                <TOCLevel3 text="<code>Object.assign</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Object.is</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Object.values</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Object.entries</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Object.fromEntries</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Object.getOwnPropertySymbols</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Object.getOwnPropertyDescriptors</code>"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="<code>Symbol.toPrimitive</code>"></TOCLevel2>
            <TOCLevel2 text="Property Order"></TOCLevel2>
            <TOCLevel2 text="Property Spread Syntax"></TOCLevel2>
            <TOCLevel2 text="Old Habits to New">
                <TOCLevel3 text="Use Computed Syntax When Creating Properties with Dynamic Names"></TOCLevel3>
                <TOCLevel3 text="Use Shorthand Syntax When Initializing a Property from a Variable with the Same Name"></TOCLevel3>
                <TOCLevel3 text={"Use <code>Object.assign</code> instead of Custom \"Extend\" Functions or Copying All Properties Explicitly"}></TOCLevel3>
                <TOCLevel3 text="Use Spread Syntax When Creating a New Object Based on an Existing Object's Properties"></TOCLevel3>
                <TOCLevel3 text="Use Symbol to Avoid Name Collision"></TOCLevel3>
                <TOCLevel3 text="Use <code>Object.getPrototypeOf</code>/<code>setPrototypeOf</code> Instead of <code>__proto__</code>"></TOCLevel3>
                <TOCLevel3 text="Use Method Syntax for Methods"></TOCLevel3>
            </TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 6: Iterables, Iterators, <code>for-of</code>, Iterable Spread, Generators">
            <TOCLevel2 text="Iterators, Iterables, the <code>for-of</code> Loop, and Iterable Spread Syntax">
                <TOCLevel3 text="Iterators and Iterables"></TOCLevel3>
                <TOCLevel3 text="The <code>for-of</code> Loop: Using an Iterator Implicitly"></TOCLevel3>
                <TOCLevel3 text="Using an Iterator Explicitly"></TOCLevel3>
                <TOCLevel3 text="Stoping Iteration Early"></TOCLevel3>
                <TOCLevel3 text="Iterator Prototype Objects"></TOCLevel3>
                <TOCLevel3 text="Making Something Iterable"></TOCLevel3>
                <TOCLevel3 text="Iterable Iterators"></TOCLevel3>
                <TOCLevel3 text="Iterable Spread Syntax"></TOCLevel3>
                <TOCLevel3 text="Iterators, <code>for-of</code>, and the DOM"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Generator Functions">
                <TOCLevel3 text="A Basic Generator Function Just Producing Values"></TOCLevel3>
                <TOCLevel3 text="Using Generator Functions to Create Iterators"></TOCLevel3>
                <TOCLevel3 text="Generator Functions As Methods"></TOCLevel3>
                <TOCLevel3 text="Using a Generator Directly"></TOCLevel3>
                <TOCLevel3 text="Consuming Values with Generators"></TOCLevel3>
                <TOCLevel3 text="Using <code>return</code> in a Generator Function"></TOCLevel3>
                <TOCLevel3 text="Precedence of the <code>yield</code> Operator"></TOCLevel3>
                <TOCLevel3 text="The return and throw Methods: Terminating a Generator"></TOCLevel3>
                <TOCLevel3 text="Yielding a Generator or Iterable: <code>yield</code>*"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Old Habits to New">
                <TOCLevel3 text="Use Constructs That Consume Iterables"></TOCLevel3>
                <TOCLevel3 text="Use DOM Collection Iteration Features"></TOCLevel3>
                <TOCLevel3 text="Use the Iterable and Iterator Interfaces"></TOCLevel3>
                <TOCLevel3 text="Use Iterable Spread Syntax in Most Places You Used to Use <code>Function.prototype.apply</code>"></TOCLevel3>
                <TOCLevel3 text="Use Generators"></TOCLevel3>
            </TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 7: Destructuring">
            <TOCLevel2 text="Overview"></TOCLevel2>
            <TOCLevel2 text="Basic Object Destructuring"></TOCLevel2>
            <TOCLevel2 text="Basic Array (and Iterable) Destructuring"></TOCLevel2>
            <TOCLevel2 text="Defaults"></TOCLevel2>
            <TOCLevel2 text="Rest Syntax in Destructuring Patterns"></TOCLevel2>
            <TOCLevel2 text="Using Different Names"></TOCLevel2>
            <TOCLevel2 text="Computed Property Names"></TOCLevel2>
            <TOCLevel2 text="Nested Destructuring"></TOCLevel2>
            <TOCLevel2 text="Parameter Destructuring"></TOCLevel2>
            <TOCLevel2 text="Destructuring in Loops"></TOCLevel2>
            <TOCLevel2 text="Old Habits to New">
                <TOCLevel3 text="Use Destructuring When Getting Only Some Properties from an Object"></TOCLevel3>
                <TOCLevel3 text="Use Destructuring for Options Objects"></TOCLevel3>
            </TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 8: Promises">
            <TOCLevel2 text="Why Promises?"></TOCLevel2>
            <TOCLevel2 text="Promise Fundamentals">
                <TOCLevel3 text="Overview"></TOCLevel3>
                <TOCLevel3 text="Example"></TOCLevel3>
                <TOCLevel3 text={"Promises and \"Thenables\""}></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Using an Existing Promise">
                <TOCLevel3 text="The <code>then</code> Method"></TOCLevel3>
                <TOCLevel3 text="Chaining Promises"></TOCLevel3>
                <TOCLevel3 text="Comparison with Callbacks"></TOCLevel3>
                <TOCLevel3 text="The <code>catch</code> Method"></TOCLevel3>
                <TOCLevel3 text="The <code>finally</code> Method"></TOCLevel3>
                <TOCLevel3 text="<code>throw</code> in <code>then</code>, <code>catch</code>, and <code>finally</code> Handlers"></TOCLevel3>
                <TOCLevel3 text="The <code>then</code> Method with Two Arguments"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Adding Handlers to Already Settled Promises"></TOCLevel2>
            <TOCLevel2 text="Creating Promises">
                <TOCLevel3 text="The <code>Promise</code> Constructor"></TOCLevel3>
                <TOCLevel3 text="<code>Promise.resolve</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Promise.reject</code>"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Other Promise Utility Methods">
                <TOCLevel3 text="<code>Promise.all</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Promise.race</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Promise.allSettled</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Promise.any</code>"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Promise Patterns">
                <TOCLevel3 text="Handle Errors or Return the Promise"></TOCLevel3>
                <TOCLevel3 text="Promises in Series"></TOCLevel3>
                <TOCLevel3 text="Promises in Parallel"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Promise Anti-Patterns">
                <TOCLevel3 text="Unnecessary <code>new Promise(/*...*/)</code>"></TOCLevel3>
                <TOCLevel3 text="Not Handling Errors (or Not Properly)"></TOCLevel3>
                <TOCLevel3 text="Letting Errors Go Unnoticed When Converting a Callback API"></TOCLevel3>
                <TOCLevel3 text="Implicitly Converting Rejection to Fulfillment"></TOCLevel3>
                <TOCLevel3 text="Trying to Use Results Outside the Chain"></TOCLevel3>
                <TOCLevel3 text="Using Do-Nothing Handlers"></TOCLevel3>
                <TOCLevel3 text="Branching the Chain Incorrectly"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Promise Subclasses"></TOCLevel2>
            <TOCLevel2 text="Old Habits to New">
                <TOCLevel3 text="Use Promises Instead of Success/Failure Callbacks"></TOCLevel3>
            </TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 9: Asynchronous Functions, Iterators, and Generators">
            <TOCLevel2 text="<code>async</code> Functions">
                <TOCLevel3 text="<code>async</code> Functions Create Promises"></TOCLevel3>
                <TOCLevel3 text="<code>await</code> Consumes Promises"></TOCLevel3>
                <TOCLevel3 text="Standard Logic Is Asynchronous When await Is Used"></TOCLevel3>
                <TOCLevel3 text="Rejections Are Exceptions, Exceptions Are Rejections; Fulfillments Are Results, Returns Are Resolutions"></TOCLevel3>
                <TOCLevel3 text="Parallel Operations in <code>async</code> Functions"></TOCLevel3>
                <TOCLevel3 text="You Don't Need <code>return await</code>"></TOCLevel3>
                <TOCLevel3 text="Pitfall: Using an a<code>sync</code> Function in an Unexpected Place"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="async Iterators, Iterables, and Generators">
                <TOCLevel3 text="Asynchronous Iterators"></TOCLevel3>
                <TOCLevel3 text="Asynchronous Generators"></TOCLevel3>
                <TOCLevel3 text="<code>for-await-of</code>"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Old Habits to New">
                <TOCLevel3 text="Use <code>async</code> Functions and <code>await</code> Instead of Explicit Promises and <code>then</code>/<code>catch</code>"></TOCLevel3>
            </TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 10: Templates, Tag Functions, and New String Features">
            <TOCLevel2 text="Template Literals">
                <TOCLevel3 text="Basic Functionality (Untagged Template Literals)"></TOCLevel3>
                <TOCLevel3 text="Template Tag Functions (Tagged Template Literals)"></TOCLevel3>
                <TOCLevel3 text="<code>String.raw</code>"></TOCLevel3>
                <TOCLevel3 text="Reusing Template Literals"></TOCLevel3>
                <TOCLevel3 text="Template Literals and Automatic Semicolon Insertion"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Improved Unicode Support">
                <TOCLevel3 text="Unicode, and What Is a JavaScript String?"></TOCLevel3>
                <TOCLevel3 text="Code Point Escape Sequence"></TOCLevel3>
                <TOCLevel3 text="<code>String.fromCodePoint</code>"></TOCLevel3>
                <TOCLevel3 text="<code>String.prototype.codePointAt</code>"></TOCLevel3>
                <TOCLevel3 text="<code>String.prototype.normalize</code>"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Iteration"></TOCLevel2>
            <TOCLevel2 text="New String Methods">
                <TOCLevel3 text="<code>String.prototype.repeat</code>"></TOCLevel3>
                <TOCLevel3 text="<code>String.prototype.startsWith, endsWith</code>"></TOCLevel3>
                <TOCLevel3 text="<code>String.prototype.includes</code>"></TOCLevel3>
                <TOCLevel3 text="<code>String.prototype.padStart, padEnd</code>"></TOCLevel3>
                <TOCLevel3 text="<code>String.prototype.trimStart, trimEnd</code>"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Updates to the <code>match</code>, <code>split</code>, <code>search</code>, and <code>replace</code> Methods"></TOCLevel2>
            <TOCLevel2 text="Old Habits to New">
                <TOCLevel3 text="Use Template Literals Instead of String Concatenation (Where Appropriate)"></TOCLevel3>
                <TOCLevel3 text="Use Tag Functions and Template Literals for DSLs Instead of Custom Placeholder Mechanisms"></TOCLevel3>
                <TOCLevel3 text="Use String Iterators"></TOCLevel3>
            </TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 11: New Array Features, Typed Arrays">
            <TOCLevel2 text="New Array Methods">
                <TOCLevel3 text="<code>Array.of</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Array.from</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Array.prototype.keys</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Array.prototype.values</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Array.prototype.entries</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Array.prototype.copyWithin</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Array.prototype.find</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Array.prototype.findIndex</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Array.prototype.fill</code>">
                    <TOCLevel4 level="4" text="Common Pitfall: Using an Object As the Fill Value"></TOCLevel4>
                </TOCLevel3>
                <TOCLevel3 text="<code>Array.prototype.includes</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Array.prototype.flat</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Array.prototype.flatMap</code>"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Iteration, Spread, Destructuring"></TOCLevel2>
            <TOCLevel2 text="Stable Array Sort"></TOCLevel2>
            <TOCLevel2 text="Typed Arrays">
                <TOCLevel3 text="Overview"></TOCLevel3>
                <TOCLevel3 text="Basic Use">
                    <TOCLevel4 level="4" text="Value Conversion Details"></TOCLevel4>
                </TOCLevel3>
                <TOCLevel3 text="<code>ArrayBuffer</code>: The Storage Used by Typed Arrays"></TOCLevel3>
                <TOCLevel3 text="Endianness (Byte Order)"></TOCLevel3>
                <TOCLevel3 text="DataView: Raw Access to the Buffer"></TOCLevel3>
                <TOCLevel3 text="Sharing an <code>ArrayBuffer</code> Between Arrays">
                    <TOCLevel4 text="Sharing Without Overlap"></TOCLevel4>
                    <TOCLevel4 text="Sharing with Overlap"></TOCLevel4>
                </TOCLevel3>
                <TOCLevel3 text="Subclassing Typed Arrays"></TOCLevel3>
                <TOCLevel3 text="Typed Array Methods">
                    <TOCLevel4 text="Standard Array Methods"></TOCLevel4>
                    <TOCLevel4 text="<code>%TypedArray%.prototype.set</code>"></TOCLevel4>
                    <TOCLevel4 text="<code>%TypedArray%.prototype.subarray</code>"></TOCLevel4>
                </TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Old Habits to New">
                <TOCLevel3 text="Use <code>find</code> and <code>findIndex</code> to Search Arrays Instead of Loops (Where Appropriate)"></TOCLevel3>
                <TOCLevel3 text="Use <code>Array.fill</code> to Fill Arrays Rather Than Loops"></TOCLevel3>
                <TOCLevel3 text="Use <code>readAsArrayBuffer</code> Instead of <code>readAsBinaryString</code>"></TOCLevel3>
            </TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 12: Maps and Sets">
            <TOCLevel2 text="Maps">
                <TOCLevel3 text="Basic Map Operations"></TOCLevel3>
                <TOCLevel3 text="Key Equality"></TOCLevel3>
                <TOCLevel3 text="Creating Maps from Iterables"></TOCLevel3>
                <TOCLevel3 text="Iterating the Map Contents"></TOCLevel3>
                <TOCLevel3 text="Subclassing Map"></TOCLevel3>
                <TOCLevel3 text="Performance"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Sets">
                <TOCLevel3 text="Basic Set Operations"></TOCLevel3>
                <TOCLevel3 text="Creating Sets from Iterables"></TOCLevel3>
                <TOCLevel3 text="Iterating the Set Contents"></TOCLevel3>
                <TOCLevel3 text="Subclassing Set"></TOCLevel3>
                <TOCLevel3 text="Performance"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="WeakMaps">
                <TOCLevel3 text="WeakMaps Are Not Iterable"></TOCLevel3>
                <TOCLevel3 text="Use Cases and Examples"></TOCLevel3>
                <TOCLevel3 text="Use Case: Private Information"></TOCLevel3>
                <TOCLevel3 text="Use Case: Storing Information for Objects Outside Your Control"></TOCLevel3>
                <TOCLevel3 text="Values Referring Back to the Key"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="WeakSets">
                <TOCLevel3 text="Use Case: Tracking"></TOCLevel3>
                <TOCLevel3 text="Use Case: Branding"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Old Habits to New">
                <TOCLevel3 text="Use Maps Instead of Objects for General-Purpose Maps"></TOCLevel3>
                <TOCLevel3 text="Use Sets Instead of Objects for Sets"></TOCLevel3>
                <TOCLevel3 text="Use WeakMaps for Storing Private Data Instead of Public Properties"></TOCLevel3>
            </TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 13: Modules">
            <TOCLevel2 text="Introduction to Modules"></TOCLevel2>
            <TOCLevel2 text="Module Fundamentals">
                <TOCLevel3 text="The Module Specifier"></TOCLevel3>
                <TOCLevel3 text="Basic Named Exports"></TOCLevel3>
                <TOCLevel3 text="Default Export"></TOCLevel3>
                <TOCLevel3 text="Using Modules in Browsers">
                    <TOCLevel4 text="Module Scripts Don't Delay Parsing"></TOCLevel4>
                    <TOCLevel4 text="The <code>nomodule</code> Attribute"></TOCLevel4>
                    <TOCLevel4 text="Module Specifiers on the Web"></TOCLevel4>
                </TOCLevel3>
                <TOCLevel3 text="Using Modules in Node.js">
                    <TOCLevel4 text="Module Specifiers in Node.js"></TOCLevel4>
                    <TOCLevel4 text="Node.js is Adding More Module Features"></TOCLevel4>
                </TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Renaming Exports"></TOCLevel2>
            <TOCLevel2 text="Re-Exporting Exports from Another Module"></TOCLevel2>
            <TOCLevel2 text="Renaming Imports"></TOCLevel2>
            <TOCLevel2 text="Importing a Module's Namespace Object"></TOCLevel2>
            <TOCLevel2 text="Exporting Another Module's Namespace Object"></TOCLevel2>
            <TOCLevel2 text="Importing a Module Just for Side Effects"></TOCLevel2>
            <TOCLevel2 text="Import and Export Entries">
                <TOCLevel3 text="Import Entries"></TOCLevel3>
                <TOCLevel3 text="Export Entries"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Imports Are Live and Read-Only"></TOCLevel2>
            <TOCLevel2 text="Module Instances Are Realm-Specific"></TOCLevel2>
            <TOCLevel2 text="How Modules Are Loaded">
                <TOCLevel3 text="Fetching and Parsing"></TOCLevel3>
                <TOCLevel3 text="Instantiation"></TOCLevel3>
                <TOCLevel3 text="Evaluation"></TOCLevel3>
                <TOCLevel3 text="Temporal Dead Zone (TDZ) Review"></TOCLevel3>
                <TOCLevel3 text="Cyclic Dependencies and the TDZ"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Import/Export Syntax Review">
                <TOCLevel3 text="Export Varieties"></TOCLevel3>
                <TOCLevel3 text="Import Varieties"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Dynamic Import">
                <TOCLevel3 text="Importing a Module Dynamically"></TOCLevel3>
                <TOCLevel3 text="Dynamic Module Example"></TOCLevel3>
                <TOCLevel3 text="Dynamic Import in Non-Module Scripts"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Tree Shaking"></TOCLevel2>
            <TOCLevel2 text="Bundling"></TOCLevel2>
            <TOCLevel2 text="Import Metadata"></TOCLevel2>
            <TOCLevel2 text="Worker Modules">
                <TOCLevel3 text="Loading a Web Worker as a Module"></TOCLevel3>
                <TOCLevel3 text="Loading a Node.js Worker as a Module"></TOCLevel3>
                <TOCLevel3 text="A Worker Is in Its Own Realm"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Old Habits to New">
                <TOCLevel3 text="Use Modules Instead of Pseudo-Namespaces"></TOCLevel3>
                <TOCLevel3 text="Use Modules Instead of Wrapping Code in Scoping Functions"></TOCLevel3>
                <TOCLevel3 text="Use Modules to Avoid Creating Megalithic Code Files"></TOCLevel3>
                <TOCLevel3 text="Convert CJS, AMD, and Other Modules to ESM"></TOCLevel3>
                <TOCLevel3 text="Use a Well-Maintained Bundler Rather Than Going Homebrew"></TOCLevel3>
            </TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 14: Reflection&nbsp;&mdash; <code>Reflect</code> and <code>Proxy</code>">
            <TOCLevel2 text="<code>Reflect</code>">
                <TOCLevel3 text="<code>Reflect.apply</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Reflect.construct</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Reflect.ownKeys</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Reflect.get</code>, <code>Reflect.set</code>"></TOCLevel3>
                <TOCLevel3 text="Other <code>Reflect</code> Functions"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="<code>Proxy</code>">
                <TOCLevel3 text="Example: Logging Proxy"></TOCLevel3>
                <TOCLevel3 text="Proxy Traps">
                    <TOCLevel4 text="Common Features"></TOCLevel4>
                    <TOCLevel4 text="The <code>apply</code> Trap"></TOCLevel4>
                    <TOCLevel4 text="The <code>construct</code> Trap"></TOCLevel4>
                    <TOCLevel4 text="The <code>defineProperty</code> Trap"></TOCLevel4>
                    <TOCLevel4 text="The <code>deleteProperty</code> Trap"></TOCLevel4>
                    <TOCLevel4 text="The <code>get</code> Trap"></TOCLevel4>
                    <TOCLevel4 text="The <code>getOwnPropertyDescriptor</code> Trap"></TOCLevel4>
                    <TOCLevel4 text="The <code>getPrototypeOf</code> Trap"></TOCLevel4>
                    <TOCLevel4 text="The <code>has</code> Trap"></TOCLevel4>
                    <TOCLevel4 text="The <code>isExtensible</code> Trap"></TOCLevel4>
                    <TOCLevel4 text="The <code>ownKeys</code> Trap"></TOCLevel4>
                    <TOCLevel4 text="The <code>preventExtensions</code> Trap"></TOCLevel4>
                    <TOCLevel4 text="The <code>set</code> Trap"></TOCLevel4>
                    <TOCLevel4 text="The <code>setPrototypeOf</code> Trap"></TOCLevel4>
                </TOCLevel3>
                <TOCLevel3 text="Example: Hiding Properties"></TOCLevel3>
                <TOCLevel3 text="Revocable Proxies"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Old Habits to New">
                <TOCLevel3 text="Use Proxies Rather Than Relying on Consumer Code Not to Modify API Objects"></TOCLevel3>
                <TOCLevel3 text="Use Proxies to Separate Implementation Code from Instrumenting Code"></TOCLevel3>
            </TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 15: Regular Expression Updates">
            <TOCLevel2 text="The <code>flags</code> Property"></TOCLevel2>
            <TOCLevel2 text="New Flags">
                <TOCLevel3 text="The Sticky Flag (<code>y</code>)"></TOCLevel3>
                <TOCLevel3 text="The Unicode Flag (<code>u</code>)"></TOCLevel3>
                <TOCLevel3 text={"The \"Dot All\" Flag (<code>s</code>)"}></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Named Capture Groups">
                <TOCLevel3 text="Basic Functionality"></TOCLevel3>
                <TOCLevel3 text="Backreferences"></TOCLevel3>
                <TOCLevel3 text="Replacement Tokens"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Lookbehind Assertions">
                <TOCLevel3 text="Positive Lookbehind"></TOCLevel3>
                <TOCLevel3 text="Negative Lookbehind"></TOCLevel3>
                <TOCLevel3 text="Greediness Is Right-to-Left in Lookbehinds"></TOCLevel3>
                <TOCLevel3 text="Capture Group Numbering and References"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Unicode Features">
                <TOCLevel3 text="Code Point Escapes"></TOCLevel3>
                <TOCLevel3 text="Unicode Property Escapes"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Old Habits to New">
                <TOCLevel3 text="Use the Sticky Flag (<code>y</code>) Instead of Creating Substrings and Using <code>^</code> When Parsing"></TOCLevel3>
                <TOCLevel3 text="Use the Dot All Flag (<code>s</code>) Instead of Using Workarounds to Match All Characters (Including Line Breaks)"></TOCLevel3>
                <TOCLevel3 text="Use Named Capture Groups Instead of Anonymous Ones"></TOCLevel3>
                <TOCLevel3 text="Use Lookbehinds Instead of Various Workarounds"></TOCLevel3>
                <TOCLevel3 text="Use Code Point Escapes Instead of Surrogate Pairs in Regular Expressions"></TOCLevel3>
                <TOCLevel3 text="Use Unicode Patterns Instead of Workarounds"></TOCLevel3>
            </TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 16: Shared Memory">
            <TOCLevel2 text="Introduction"></TOCLevel2>
            <TOCLevel2 text="Here There Be Dragons!"></TOCLevel2>
            <TOCLevel2 text="Browser Support"></TOCLevel2>
            <TOCLevel2 text="Shared Memory Basics">
                <TOCLevel3 text="Critical Sections, Locks, and Condition Variables"></TOCLevel3>
                <TOCLevel3 text="Creating Shared Memory"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Memory Is Shared, Not Objects"></TOCLevel2>
            <TOCLevel2 text="Race Conditions, Out-of-Order Stores, Stale Values, Tearing, and More"></TOCLevel2>
            <TOCLevel2 text="The <code>Atomics</code> Object">
                <TOCLevel3 text="Low-Level <code>Atomics</code> Features"></TOCLevel3>
                <TOCLevel3 text="Using <code>Atomics</code> to Suspend and Resume Threads"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Shared Memory Example"></TOCLevel2>
            <TOCLevel2 text="Here There Be Dragons! (Again)"></TOCLevel2>
            <TOCLevel2 text="Old Habits to New">
                <TOCLevel3 text="Use Shared Blocks Rather Than Exchanging Large Data Blocks Repeatedly"></TOCLevel3>
                <TOCLevel3 text="Use <code>Atomics.wait</code> and <code>Atomics.notify</code> Instead of Breaking Up Worker Jobs to Support the Event Loop (Where Appropriate)"></TOCLevel3>
            </TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 17: Miscellany">
            <TOCLevel2 text="BigInt">
                <TOCLevel3 text="Creating a BigInt"></TOCLevel3>
                <TOCLevel3 text="Explicit and Implicit Conversion"></TOCLevel3>
                <TOCLevel3 text="Performance"></TOCLevel3>
                <TOCLevel3 text="<code>BigInt64Array</code> and <code>BigUint64Array</code>"></TOCLevel3>
                <TOCLevel3 text="Utility Functions"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="New Integer Literals">
                <TOCLevel3 text="Binary Integer Literals"></TOCLevel3>
                <TOCLevel3 text="Octal Integer Literals, Take II"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="New Math Methods">
                <TOCLevel3 text="General Math Functions"></TOCLevel3>
                <TOCLevel3 text="Low-Level Math Support Functions"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Exponentiation Operator (<code>**</code>)"></TOCLevel2>
            <TOCLevel2 text="<code>Date.prototype.toString</code> Change"></TOCLevel2>
            <TOCLevel2 text="<code>Function.prototype.toString</code> Change"></TOCLevel2>
            <TOCLevel2 text="Number Additions">
                <TOCLevel3 text={"\"Safe\" Integers"}>
                    <TOCLevel4 text="<code>Number.MAX_SAFE_INTEGER</code>, <code>Number.MIN_SAFE_INTEGER</code>"></TOCLevel4>
                    <TOCLevel4 text="<code>Number.isSafeInteger</code>"></TOCLevel4>
                </TOCLevel3>
                <TOCLevel3 text="<code>Number.isInteger</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Number.isFinite</code>, <code>Number.isNaN</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Number.parseInt</code>, <code>Number.parseFloat</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Number.EPSILON</code>"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="<code>Symbol.isConcatSpreadable</code>"></TOCLevel2>
            <TOCLevel2 text="Various Syntax Tweaks">
                <TOCLevel3 text="Nullish Coalescing"></TOCLevel3>
                <TOCLevel3 text="Optional Chaining"></TOCLevel3>
                <TOCLevel3 text="Optional <code>catch</code> Bindings"></TOCLevel3>
                <TOCLevel3 text="Unicode Line Breaks in JSON"></TOCLevel3>
                <TOCLevel3 text="Well-Formed JSON from JSON.stringify"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Various Standard Library / Global Additions">
                <TOCLevel3 text="<code>Symbol.hasInstance</code>"></TOCLevel3>
                <TOCLevel3 text="<code>Symbol.unscopables</code>"></TOCLevel3>
                <TOCLevel3 text="<code>globalThis</code>"></TOCLevel3>
                <TOCLevel3 text="Symbol <code>description</code> Property"></TOCLevel3>
                <TOCLevel3 text="<code>String.prototype.matchAll</code>"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Annex B: Browser-Only Features">
                <TOCLevel3 text="HTML-Like Comments"></TOCLevel3>
                <TOCLevel3 text="Regular Expression Tweaks">
                    <TOCLevel4 text="Control Character Escape (cX) Extension"></TOCLevel4>
                    <TOCLevel4 text="Tolerating Invalid Sequences"></TOCLevel4>
                    <TOCLevel4 text="<code>RegExp.prototype.compile</code>"></TOCLevel4>
                </TOCLevel3>
                <TOCLevel3 text="Additional Built-In Properties">
                    <TOCLevel4 text="Additional Object Properties"></TOCLevel4>
                    <TOCLevel4 text="Additional String Methods"></TOCLevel4>
                </TOCLevel3>
                <TOCLevel3 text="Various Bits of Loosened or Obscure Syntax"></TOCLevel3>
                <TOCLevel3 text="When <code>document.all</code> Isn't There ... or Is It?"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Tail Call Optimization"></TOCLevel2>
            <TOCLevel2 text="Old Habits to New">
                <TOCLevel3 text="Use Binary Literals"></TOCLevel3>
                <TOCLevel3 text="Use New Math Functions Instead of Various Math Workarounds"></TOCLevel3>
                <TOCLevel3 text="Use Nullish Coalescing for Defaults"></TOCLevel3>
                <TOCLevel3 text="Use Optional Chaining Instead of <code>&amp;&amp;</code> Checks"></TOCLevel3>
                <TOCLevel3 text={"Leave the Error Binding (<code>e</code>) Off of \"<code>catch (e)</code>\" When Not Using It"}></TOCLevel3>
                <TOCLevel3 text="Use the Exponentiation Operator (<code>**</code>) Rather Than <code>Math.pow</code>"></TOCLevel3>
            </TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 18: Upcoming Class Features">
            <TOCLevel2 text="Public and Private Class Fields, Methods, and Accessors">
                <TOCLevel3 text="Public Field (Property) Definitions"></TOCLevel3>
                <TOCLevel3 text="Private Fields"></TOCLevel3>
                <TOCLevel3 text="Private Instance Methods and Accessors">
                    <TOCLevel4 text="Private Methods"></TOCLevel4>
                    <TOCLevel4 text="Private Accessors"></TOCLevel4>
                </TOCLevel3>
                <TOCLevel3 text="Public Static Fields, Private Static Fields, and Private Static Methods">
                    <TOCLevel4 text="Public Static Fields"></TOCLevel4>
                    <TOCLevel4 text="Private Static Fields"></TOCLevel4>
                    <TOCLevel4 text="Private Static Methods"></TOCLevel4>
                </TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Old Habits to New">
                <TOCLevel3 text="Use Property Definitions Instead of Creating Properties in the Constructor (Where Appropriate)"></TOCLevel3>
                <TOCLevel3 text="Use Private Fields Instead of Prefixes (Where Appropriate)"></TOCLevel3>
                <TOCLevel3 text="Use Private Methods Instead of Functions Outside the Class for Private Operations"></TOCLevel3>
            </TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 text="Chapter 19: A Look Ahead ...">
            <TOCLevel2 text="Top-Level await">
                <TOCLevel3 text="Overview and Use Cases"></TOCLevel3>
                <TOCLevel3 text="Example"></TOCLevel3>
                <TOCLevel3 text="Error Handling"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="WeakRefs and Cleanup Callbacks">
                <TOCLevel3 text="WeakRefs"></TOCLevel3>
                <TOCLevel3 text="Cleanup Callbacks"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="RegExp Match Indices"></TOCLevel2>
            <TOCLevel2 text="<code>String.prototype.replaceAll</code>"></TOCLevel2>
            <TOCLevel2 text="<code>Atomics.asyncWait</code>"></TOCLevel2>
            <TOCLevel2 text="Various Syntax Tweaks">
                <TOCLevel3 text="Numeric Separators"></TOCLevel3>
                <TOCLevel3 text="Hashbang Support"></TOCLevel3>
            </TOCLevel2>
            <TOCLevel2 text="Legacy Deprecated RegExp Features"></TOCLevel2>
            <TOCLevel2 text="Thank You for Reading!"></TOCLevel2>
        </TOCLevel1>
        <TOCLevel1 className="toc--empty" text="Appendix: Fantastic Features and Where to Find Them"></TOCLevel1>
        <TOCLevel1 className="toc--empty" text="Index"></TOCLevel1>
    </div>
);

const TOCPage = () => (
    <Layout>
        <PageInfo title="Book - Table of Contents" />
        <SidebarWrapper className="toc" main={<>
            <TOC />
        </>}/>
    </Layout>
);

export default TOCPage;
