// Copyright (C) 2020 Farsight Software Ltd.. All rights reserved.
// Originally based on the index.js in Gatsby's barebones starter, which is covered by the MIT
// license; see LICENSE for details.
import React from "react";

export const BuyLink = ({
    children,
    className = "",
    to,
    noDeco = false,
    button = true,
    buttonColor = "yellow",
    tabIndex,
    target = "_blank",
}) => {
    if (noDeco) {
        className += " link--subtle";
    }
    if (button) {
        className += ` button button--${buttonColor}`;
    }
    return (
        <a tabIndex={tabIndex} href={to} className={className} target={target}>
            {children}
        </a>
    );
} ;